/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2020-09-14 14:57:16
 * @Last Modified by: shuwen.chen
 * @Last Modified time: 2021-01-05 09:35:05
 * @Description: 导入导出列表
 * @Route: Route
 */

<template>
  <div class="question-list">
    <h2 class="title">
      {{ operationStatus == 1 ? '导入记录' : '导出记录' }}
    </h2>
    <div class="list_wrap">
      <bk-list ref="list" :table-config="tableConfig" />
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/util';

export default {
  data() {
    const arr = [
      { id: 1, name: '生成中', color: ' #1890FF' },
      { id: 2, name: '导入中', color: ' #1890FF' },
      { id: 3, name: '已完成', color: ' #52C41A' },
      { id: 4, name: '失败', color: ' #F5222D' },
    ];
    return {
      operationStatus: this.$route.query.operationStatus,
      tableConfig: {
        selection: false,
        http: {
          url: '/api/im-web/knowledge/importAndExportMess',
          method: 'post',
          onBefore: params => {
            const dt = params;
            dt.operationStatus = this.$route.query.operationStatus;
            return dt;
          },
        },
        data: [],
      },
      data1: [
        {
          name: 'fileName',
          label: '文件名',
        },
        {
          name: 'exportCondition',
          label: '导出条件',
        },

        {
          name: 'exportNumber',
          label: '导出QA数',
        },
        {
          name: 'questionCount',
          label: '状态',
          render(h, dt) {
            const { status } = dt.data && dt.data.attrs.row;
            const { name, color, id } = arr[status - 1];
            return (
              <div style="display: flex;align-items: center;justify-content: center;">
                <span
                  style={{
                    width: '6px',
                    height: '6px',
                    background: color,
                    'border-radius': '50%',
                    'margin-right': '5px',
                  }}
                />
                <span>{id === 2 ? '导出中' : name}</span>
              </div>
            );
          },
        },
        {
          label: '导出时间',
          render(h, params) {
            const { exportDate } = params.data.attrs.row;
            return <span>{formatDate(new Date(Number(exportDate)), 'yyyy-mm-dd HH:ii:ss')}</span>;
          },
        },
        {
          name: 'exportUser',
          label: '导出人',
        },
        {
          name: 'operation',
          label: '操作',
          type: 'operation',
          data: [
            {
              name: '刷新',
              label: '刷新',
              callback: that => {
                that.getTableData();
              },
            },
            {
              name: 'path',
              label: '下载',
              visible: row => row.status === '3',
              callback: that => {
                window.location.href = that.operationRow.path;
              },
            },
          ],
        },
      ],
      data2: [
        {
          name: 'fileName',
          label: '文件名',
        },
        {
          name: 'remarks',
          label: '导入备注',
        },
        {
          name: 'importNumber',
          label: '导入数(成功数/总数)',
        },
        {
          name: 'questionCount',
          label: '状态',
          render(h, dt) {
            const { status } = dt.data && dt.data.attrs.row;
            const { name, color, id } = arr[status - 1];
            return (
              <div style="display: flex;align-items: center;justify-content: center;">
                <span
                  style={{
                    width: '6px',
                    height: '6px',
                    background: color,
                    'border-radius': '50%',
                    'margin-right': '5px',
                  }}
                />
                <span>{id === 2 ? '导出中' : name}</span>
              </div>
            );
          },
        },
        {
          label: '导入时间',
          render(h, params) {
            const { impDate } = params.data.attrs.row;
            return <span>{formatDate(new Date(Number(impDate)), 'yyyy-mm-dd HH:ii:ss')}</span>;
          },
        },
        {
          name: 'impUser',
          label: '导入人',
        },
        {
          name: 'operation',
          label: '操作',
          type: 'operation',
          data: [
            {
              name: '刷新',
              label: '刷新',
              callback: () => {
                window.location.reload();
              },
            },
          ],
        },
      ],
    };
  },
  mounted() {
    const { operationStatus } = this;
    if (operationStatus === '1') {
      this.tableConfig.data = this.data2;
    } else {
      this.tableConfig.data = this.data1;
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  background: #fff;
  padding: 10px 32px;
}
.list_wrap {
  background: #fff;
  padding: 10px 32px;
  margin: 32px;
  height: calc(100vh - 230px);
  overflow-y: auto;
}
.form-item {
  display: flex;
  align-items: center;

  span {
    margin-right: 20px;
  }
  .el-input {
    width: 300px;
  }
}
</style>
